export const ARTICLES_ALL_REQUEST = "ARTICLES_ALL_REQUEST"
export const ARTICLES_ALL_FAILURE = "ARTICLES_ALL_FAILURE"
export const ARTICLES_ALL_RESPONSE = "ARTICLES_ALL_RESPONSE"

export const ARTICLES_ADD_MANY_REQUEST = "ARTICLES_ADD_MANY_REQUEST"
export const ARTICLES_ADD_MANY_RESPONSE = "ARTICLES_ADD_MANY_RESPONSE"

export const ARTICLE_PATCH_REQUEST = "ARTICLE_PATCH_REQUEST"
export const ARTICLE_PATCH_RESPONSE = "ARTICLE_PATCH_RESPONSE"
export const ARTICLE_PATCH_ERROR = "ARTICLE_PATCH_ERROR"

export const ARTICLE_ACT_REQUEST = "ARTICLE_ACT_REQUEST"
export const ARTICLE_ACT_RESPONSE = "ARTICLE_ACT_RESPONSE"
export const ARTICLE_ACT_ERROR = "ARTICLE_ACT_ERROR"

export const ARTICLES_ACTION_REQUEST = "ARTICLES_ACTION_REQUEST"
export const ARTICLES_ACTION_RESPONSE = "ARTICLES_ACTION_RESPONSE"
export const ARTICLES_ACTION_ERROR = "ARTICLES_ACTION_ERROR"
